import {UserRoles} from "./global"
import {MdContactPhone} from "react-icons/md";
import {BiCalendar, BiChart, BiChild, BiMenu} from "react-icons/bi";

export interface MenuInterface {
    icon: React.FC;
    title: string;
    link: string;
    pathname: string;
    roles?: UserRoles[];
    subMenus?: MenuInterface[];
}

export const MENUS: MenuInterface[] = [

    {
        icon: BiChart,
        title: "Dashboard",
        link: "/dashboard",
        pathname: "dashboard",
    },
    {
        icon: MdContactPhone,
        title: "Patients",
        link: "/clients",
        pathname: "clients"
    },
    {
        icon: BiChild,
        title: "Enfants",
        link: "/child",
        pathname: "child"
    },
    {
        icon: BiCalendar,
        title: "Date de prochain rendez-vous",
        link: "/next-meeting",
        pathname: "next-meeting"
    },



]
