import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {InputDate, InputDateTime} from "../components/form/Input";
import {HTTPStatusResponse} from "../config/global";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL} from "../config/api";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {Select2} from "../components/form/Select";
import {useState} from "react";
import {SubmitButton} from "../components/button/CustomButton";
import LoaderBarWrapper from "../components/loader/LoaderBarWrapper";
import {useMutateWithInvalidateQueries} from "../hooks/useMutations";
import moment from "moment/moment";

export default function MeetingPage() {
    /**
     * Hooks
     * */
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: {errors},
    } = useForm();

    /**
     * Query
     */
    const queryKey = ["client"];

    /**
     * Mutation
     */

    /**
     * Function
     */
    const {data: healthCenters} = useQueries(
        APP_API_URL +
        `health-center-list`,
        ["healthCenters"]
    );

    const [isLoading, setIsloading] = useState(false);
    const {mutateAsync: storeData} = useMutateWithInvalidateQueries(
        APP_API_URL + `next-date-update`,
        "put",
        queryKey
    );
    const handleUpdateForm = async (values: any) => {
        setIsloading(true);
        storeData({
            next_date: values.next_date,
            health_center: values.healthCenters.name,
        })
            .then((response: any) => {
                if (response && response.data.status === HTTPStatusResponse.OK) {
                    reset();
                    toast.success("Date du prochain rendez-vous modifier");
                } else {
                    toast.error(response?.data.message);
                }
            })
            .finally(() => setIsloading(false));
    };


    /**
     * Columns Table
     */

    /**
     * UI
     */
    return (
        <div className="relative">
            <div className="flex justify-center items-center">
                <div className="md:w-1/3 w-full">
                    <LoaderBarWrapper loading={isLoading}>
                        <Form
                            loading={isLoading}
                            onSubmit={handleSubmit(handleUpdateForm)}>
                            <Card title={"Modifier la date de prochain rendez-vous"}>
                                <div className="grid gap-8">
                                    <div>
                                        <Select2
                                            control={control}
                                            placeholder={"Selection du centre de santé"}
                                            label={"Centre de santé"}
                                            register={register}
                                            error={errors?.healthCenters}
                                            name={"healthCenters"}
                                            textKeyItem={"name"}
                                            valueKeyItem={"id"}
                                            items={healthCenters?.data?.data}
                                            required={false}
                                        />
                                    </div>
                                    <div>
                                        <InputDate
                                            label={"Entrer la date du prochain rendez-vous"}
                                            register={register}
                                            error={errors?.next_date}
                                            name={"next_date"}
                                        />
                                    </div>
                                    <div className={"flex gap-4 justify-center"}>
                                        <SubmitButton
                                            value="Enregistrer"
                                            loading={isLoading}
                                            key="submit"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Form>
                    </LoaderBarWrapper>
                </div>
            </div>
        </div>
    );
}
