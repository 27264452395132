import {useForm} from "react-hook-form";
import {APP_API_URL} from "../config/api";
import Card from "../components/card/Card";
import {useQueries} from "../hooks/useQueries";
import {useRef} from "react";
import {Form} from "../components/form/Form";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2} from "react-icons/bi";
import moment from "moment";
import {InputDate} from "../components/form/Input";
import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "../components/chart/configChart";
import {Select2} from "../components/form/Select";

export default function DashboardPage() {
    /**
     * Hooks
     */
    const startDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const endDate = useRef(moment().format("YYYY-MM-DD"));
    const town = useRef(null);

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();

    /**
     * Query
     */
    const {data: clients} = useQueries(
        APP_API_URL +
        `client-total-by-region?paginate=0${
            startDate.current ? `&startDate=${startDate.current}` : ""
        }${endDate.current ? `&endDate=${endDate.current}` : ""}
      ${town.current ? `&town=${town.current}` : ""}`,
        ["clients", startDate.current, endDate.current, town.current]
    );

    const {data: total, isLoading} = useQueries(
        APP_API_URL +
        `total?paginate=0${
            startDate.current ? `&startDate=${startDate.current}` : ""
        }${endDate.current ? `&endDate=${endDate.current}` : ""}
      ${town.current ? `&town=${town.current}` : ""}`,
        ["total", startDate.current, endDate.current, town.current]
    );

    const handleSubmitFilterForm = (values: any) => {
        startDate.current = values.startDate;
        endDate.current = values.endDate;
        town.current = values.town?.name;
    };

    /**
     * Columns Table
     */

    /**
     * UI
     */

    return (
        <>
            <Card title={"Filtres"} extras={[]}>
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
                        <div>
                            <InputDate
                                label={"Date de début"}
                                register={register}
                                error={errors?.startDate}
                                name={"startDate"}
                                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputDate
                                label={"Date de fin"}
                                register={register}
                                error={errors?.endDate}
                                name={"endDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la ville"
                                control={control}
                                name={"town"}
                                error={errors?.town}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={[
                                    {id: 1, name: "Douala"},
                                    {id: 2, name: "Yaounde"}
                                ]}
                                label="Ville"
                                required={false}
                            />
                        </div>
                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2"
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                        </div>
                    </div>
                </Form>
            </Card>
            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                <Card title="">
                    <div className="text-blue-600">TOTAL DES PATIENTS</div>
                    <div className="text-right w-full">
                        <div className="">
                            {total?.data.data.map((data: any) => data.clients)}
                        </div>
                    </div>
                </Card>
            </div>
            <div className="justify-items-center gap-4">
                <Card
                    title={
                        "Evolution journalière des enregistrements des patients par ville"
                    }
                >
                    <ReactApexChart
                        className=""
                        height="400px"
                        options={{
                            chart: CONFIG_CHART.chart,
                        }}
                        series={[
                            {
                                data: clients ? clients?.data.data.douala : [],
                                name: "DOUALA",
                            },
                            {
                                data: clients ? clients?.data.data.yaounde : [],
                                name: "YAOUNDE",
                            },

                        ]}
                        type={"bar"}
                    />
                </Card>
            </div>
        </>
    );
}