import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {useRef} from "react";
import {Select2} from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2, BiSolidDownload, BiSolidInfoCircle} from "react-icons/bi";
import {InputDate} from "../components/form/Input";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {LinkButton} from "../components/button/CustomButton";

export default function ClientPage() {
    /**
     * Hooks
     * */
    const {baseApiUrl, title, pagination, setPagination} =
        useHeaderFunctionPage({baseApiUrl: "client", title: "Patients"});
    const town = useRef(null);
    const vaccine = useRef(null);
    const fromDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const toDate = useRef(moment().format("YYYY-MM-DD"));
    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
        town.current,
        vaccine.current,
        fromDate.current,
        toDate.current,
    ];

    /**
     * Form
     */
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm();

    const {
        watch: watchFilter,
    } = useForm();

    const watchTown = watch("town", null);
    const watchFromDate = watch("fromDate", moment().subtract(7, "day").format("YYYY-MM-DD"));
    const watchToDate = watch("toDate", moment().format("YYYY-MM-DD"));

    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
            town.current ? `&town=${town.current}` : ""
        }${fromDate.current ? `&fromDate=${fromDate.current}` : ""}${
            toDate.current ? `&toDate=${toDate.current}` : ""
        }`,
        queryKey
    );

    const {
        data: realDatas,
    } = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?paginate=0${
            watchTown !== null ? `&town=${watchTown.name}` : ""
        }${watchFromDate ? `&fromDate=${watchFromDate}` : ""}${
            watchToDate ? `&toDate=${watchToDate}` : ""
        }`,
        ["codes-not-paginate", watchTown]
    );

    /**
     * Function
     */
    const handleSubmitFilterForm = (values: any) => {
        town.current = values.town?.name;
        fromDate.current = values.startDate;
        toDate.current = values.endDate;
    };


    const handleExportInExcel = () => {
        const sheetData = realDatas?.data.data.map((data: any) => {
            return {
                Téléphone: data.phone,
                Nom: data.name,
                Ville: data.town,
                /*District: data.district,
                Vaccin: data.vaccine,*/
            };
        });
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(sheetData);
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, `Patients` + fileExtension);
        toast.success("Patients exportés avec succès !");
    };
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Noms",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Téléphone",
            key: "phone",
            dataIndex: "phone",
        },
        {
            title: "Deuxième numéro de téléphone",
            key: "other_phone",
            dataIndex: "other_phone",
        },
        {
            title: "Ville",
            key: "town",
            dataIndex: "town",
        },
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD")}</>
            ),
        },
        {
            title: <span style={{textAlign: "center"}}></span>,
            dataIndex: "actions",
            key: "actions",
            render: (element: any) => (
                <Link to={`/detail/${element.id}`} state={{detail: element}}>
                    <LinkButton className="bg-soft-primary rounded flex items-center">
                        <BiSolidInfoCircle className="mr-2"/> <span>Détails</span>
                    </LinkButton>
                </Link>
            ),
        },

    ];

    /**
     * UI
     */

    return (
        <>
            <Card
                title={"Filtres"}
                extras={[
                    <SimpleButton
                        key={"export"}
                        onClick={handleExportInExcel}
                        className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
                        value={"Exporter"}
                    >
                        <BiSolidDownload className="mr-2"/> <span>Exporter</span>
                    </SimpleButton>,
                ]}
            >
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
                        <div>
                            <InputDate
                                label={"Date de début"}
                                register={register}
                                error={errors?.startDate}
                                name={"startDate"}
                                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputDate
                                label={"Date de fin"}
                                register={register}
                                error={errors?.endDate}
                                name={"endDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la ville"
                                control={control}
                                name={"town"}
                                error={errors?.town}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={[
                                    {id: 1, name: "Douala"},
                                    {id: 2, name: "Yaounde"}
                                ]}
                                label="Selectionner la ville"
                                required={false}
                            />
                        </div>
                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2     "
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                        </div>
                    </div>
                </Form>
            </Card>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    );
}
