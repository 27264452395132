import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import {Link, useLocation} from "react-router-dom";
import moment from "moment/moment";
import {BadgeRounded} from "../components/badge/Badge";

export default function DetailPage() {
    /**
     * Hooks
     */
    const {state} = useLocation();
    const detail = state?.detail;
    const {baseApiUrl, pagination, title, setPagination} =
        useHeaderFunctionPage({
            baseApiUrl: "child",
            title: `Enfants de ${detail?.name}`,
        });

    const queryKey = [
        `${baseApiUrl}`,
        pagination.page,
        pagination.perPage,
        detail.id,
    ];

    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}-find/${detail?.id}?page=${pagination.page}&perPage=${pagination.perPage}`,
        queryKey
    );
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "name",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "District",
            key: "district",
            dataIndex: "district",
        },
        {
            title: "Centre de santé",
            key: "health_center",
            dataIndex: "health_center",
        },
        {
            title: "Vaccin pour:",
            key: "vaccine",
            dataIndex: "vaccine",
        },
        {
            title: "Type de vaccin",
            key: "vaccine_type",
            dataIndex: "vaccine_type",
        },
        {
            title: "Date d'enregistrement",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD")}</>
            ),
        },
        {
            title: "Date du prochain rendez-vous",
            key: "date_created_at",
            render: (element: any) => (
                <>{moment(element.next_date).format("YYYY-MM-DD")}</>
            ),
        },
        {
            title: "Status",
            key: "win_date",
            render: (element: any) => (
                <BadgeRounded
                    className="bg-success text-white"
                    text={"À JOUR"}
                />
            ),
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <CardTable
                extras={[
                    /*<Link to={`/questions`}>
                        <LinkButton className="bg-success rounded bg-opacity-50 hover:bg-opacity-100 flex items-center">
                            Retour aux questions
                        </LinkButton>
                    </Link>,*/
                ]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    );
}
